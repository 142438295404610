import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_FAIL,
  GET_PROFILE_SUCCESS,
  USER_DESTROY_IMPERSONATION_SUCCESS,
  USER_CREATE_IMPERSONATION_SUCCESS,
  USER_CREATE_IMPERSONATION_FAIL,
  GET_USER_PROFILE_MANAGER_SUCCESS,
  USER_LOGIN_REFRESH_SUCCESS,
  USER_LOGIN_REFRESH_FAIL,
  GET_PROFILE_KIND_SUCCESS,
  GET_PROFILE_FAIL,
} from 'redux/actionTypes'

const logged = localStorage.getItem('logged')

const initialState = {
  logged: !!logged,
  loginProgress: false,
  logoutProgress: false,
  data: {},
  impersonated: false,
  impersonatedData: {},
  manager: {},
  clientProfile: {},
  clientProfileKind: {},
}

const userLoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST: {
      return {...state, loginProgress: true}
    }
    case USER_LOGIN_SUCCESS: {
      return {
        ...state,
        logged: true,
        loginProgress: false,
        data: action.data,
      }
    }
    case USER_LOGIN_FAIL: {
      return {...state, loginProgress: false}
    }
    case USER_LOGOUT_REQUEST: {
      return {...state, logoutProgress: true}
    }
    case USER_LOGOUT_SUCCESS: {
      return {
        ...state,
        logged: false,
        logoutProgress: false,
        data: {},
        impersonated: false,
        impersonatedData: {},
        clientProfile: {},
      }
    }
    case USER_LOGOUT_FAIL: {
      return {...state, logoutProgress: false}
    }
    case USER_LOGIN_REFRESH_SUCCESS: {
      return {...state, data: action.data, logged: true}
    }
    case USER_LOGIN_REFRESH_FAIL: {
      return {...state, logged: false}
    }
    case GET_PROFILE_SUCCESS: {
      return {...state, clientProfile: action.data}
    }
    case GET_PROFILE_FAIL: {
      return {...state, clientProfile: {}}
    }
    case GET_PROFILE_KIND_SUCCESS: {
      return {...state, clientProfileKind: action.data}
    }
    case USER_CREATE_IMPERSONATION_SUCCESS: {
      return {
        ...state,
        impersonated: true,
        impersonatedData: action.data,
      }
    }
    case USER_CREATE_IMPERSONATION_FAIL: {
      return {...state, impersonated: false, impersonatedData: {}}
    }
    case USER_DESTROY_IMPERSONATION_SUCCESS: {
      return {
        ...state,
        impersonated: false,
        impersonatedData: {},
        clientProfile: {},
      }
    }
    case GET_USER_PROFILE_MANAGER_SUCCESS: {
      return {...state, manager: action.data}
    }
    default: {
      return state
    }
  }
}

export default userLoginReducer
