import endpoints from 'helper/endpoints'
import {
  globalApiErrorHandler,
  globalLoginErrorHandler,
  globalLoginRefreshErrorHandler,
  rethrow,
} from 'helper/functions'
import httpClient from 'helper/httpClient'
import {
  GET_USER_PROFILE_MANAGER_FAIL,
  GET_USER_PROFILE_MANAGER_REQUEST,
  GET_USER_PROFILE_MANAGER_SUCCESS,
  USER_CREATE_IMPERSONATION_FAIL,
  USER_CREATE_IMPERSONATION_REQUEST,
  USER_CREATE_IMPERSONATION_SUCCESS,
  USER_DESTROY_IMPERSONATION_FAIL,
  USER_DESTROY_IMPERSONATION_REQUEST,
  USER_DESTROY_IMPERSONATION_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT_FAIL,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  EDIT_PROFILE_FAIL,
  EDIT_PROFILE_REQUEST,
  EDIT_PROFILE_SUCCESS,
  EDIT_PASSWORD_FAIL,
  EDIT_PASSWORD_REQUEST,
  EDIT_PASSWORD_SUCCESS,
  GET_PROFILE_FAIL,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_KIND_FAIL,
  GET_PROFILE_KIND_REQUEST,
  GET_PROFILE_KIND_SUCCESS,
  USER_LOGIN_REFRESH_REQUEST,
  USER_LOGIN_REFRESH_SUCCESS,
  USER_LOGIN_REFRESH_FAIL,
  RESET_LEASE,
  RESET_CONDUCT,
} from 'redux/actionTypes'

export const userLoginRefresh = () =>
  function (dispatch) {
    const logged = localStorage.getItem('logged')
    if (!logged) {
      dispatch({type: USER_LOGIN_REFRESH_FAIL})
      return
    }
    dispatch({type: USER_LOGIN_REFRESH_REQUEST})
    return httpClient
      .get(endpoints.authProfile)
      .then((response) => {
        localStorage.setItem('logged', 'true')
        dispatch({
          type: USER_LOGIN_REFRESH_SUCCESS,
          data: response.data,
        })
      })
      .catch(
        rethrow(() => {
          localStorage.removeItem('logged')
          dispatch({type: USER_LOGIN_REFRESH_FAIL})
        })
      )
      .catch(globalLoginRefreshErrorHandler)
      .catch(globalApiErrorHandler)
  }

export const userLogin = (loginData) =>
  function (dispatch) {
    dispatch({type: USER_LOGIN_REQUEST})
    return httpClient
      .post(endpoints.login, {
        email: loginData.email,
        password: loginData.password,
      })
      .then((response) => {
        localStorage.setItem('logged', 'true')
        dispatch({
          type: USER_LOGIN_SUCCESS,
          data: {...response.data},
        })
      })
      .catch(
        rethrow(() => {
          localStorage.removeItem('logged')
          dispatch({type: USER_LOGIN_FAIL})
        })
      )
      .catch(globalLoginErrorHandler)
      .catch(globalApiErrorHandler)
  }

export const userLogout = () =>
  function (dispatch) {
    dispatch(destroyUserImpersonation(true))
      .then(() => {
        dispatch({type: USER_LOGOUT_REQUEST})
        return httpClient
          .del(endpoints.logout)
          .then((response) => {
            dispatch({
              type: USER_LOGOUT_SUCCESS,
              data: response.data,
            })
            localStorage.removeItem('logged')
            localStorage.removeItem('impersonation')
          })
          .catch(rethrow(() => dispatch({type: USER_LOGOUT_FAIL})))
          .catch(globalApiErrorHandler)
      })
      .catch(rethrow(() => dispatch({type: USER_LOGOUT_FAIL})))
      .catch(globalApiErrorHandler)
  }

export const getClientProfile = () =>
  function (dispatch) {
    dispatch({type: GET_PROFILE_REQUEST})
    return httpClient
      .get(endpoints.clientProfile)
      .then((response) => {
        dispatch({
          type: GET_PROFILE_SUCCESS,
          data: response.data,
        })
        return response.data
      })
      .catch(rethrow(() => dispatch({type: GET_PROFILE_FAIL})))
      .catch(globalApiErrorHandler)
  }

export const getUserProfileWithKind = (kind) =>
  function (dispatch) {
    dispatch({type: GET_PROFILE_KIND_REQUEST})
    return httpClient
      .get(`${endpoints.clientProfile}/${kind}`)
      .then((response) => {
        dispatch({
          type: GET_PROFILE_KIND_SUCCESS,
          data: response.data,
        })
      })
      .catch(rethrow(() => dispatch({type: GET_PROFILE_KIND_FAIL})))
      .catch(globalApiErrorHandler)
  }

export const editUserProfileWithKind = (data, kind) =>
  function (dispatch) {
    dispatch({type: EDIT_PROFILE_REQUEST})
    return httpClient
      .patch(`${endpoints.clientProfile}/${kind}`, data)
      .then((response) => {
        dispatch({
          type: EDIT_PROFILE_SUCCESS,
          data: response.data,
        })
      })
      .catch(rethrow(() => dispatch({type: EDIT_PROFILE_FAIL})))
      .catch(globalApiErrorHandler)
  }

export const editUserPassword = (data) =>
  function (dispatch) {
    dispatch({type: EDIT_PASSWORD_REQUEST})
    return httpClient
      .patch(endpoints.password, data)
      .then((response) => {
        dispatch({
          type: EDIT_PASSWORD_SUCCESS,
          data: response.data,
        })
      })
      .catch(rethrow(() => dispatch({type: EDIT_PASSWORD_FAIL})))
      .catch(globalApiErrorHandler)
  }

export const getUserProfileManager = () =>
  function (dispatch) {
    dispatch({type: GET_USER_PROFILE_MANAGER_REQUEST})
    return httpClient
      .get(endpoints.manager)
      .then((response) => {
        dispatch({
          type: GET_USER_PROFILE_MANAGER_SUCCESS,
          data: response.data,
        })
      })
      .catch(rethrow(() => dispatch({type: GET_USER_PROFILE_MANAGER_FAIL})))
      .catch(globalApiErrorHandler)
  }

export const createUserImpersonation = (clientId) =>
  function (dispatch) {
    dispatch({type: USER_CREATE_IMPERSONATION_REQUEST})
    return httpClient
      .post(endpoints.impersonation, {
        client_id: clientId,
      })
      .then((response) => {
        localStorage.setItem('impersonation', JSON.stringify(response.data))
        dispatch({
          type: USER_CREATE_IMPERSONATION_SUCCESS,
          data: response.data,
        })
      })
      .catch(rethrow(() => dispatch({type: USER_CREATE_IMPERSONATION_FAIL})))
      .catch(globalLoginErrorHandler)
      .catch(globalApiErrorHandler)
  }

export const destroyUserImpersonation = (isLogout = false) =>
  function (dispatch) {
    dispatch({type: USER_DESTROY_IMPERSONATION_REQUEST})
    return httpClient
      .del(endpoints.impersonation)
      .then((response) => {
        !isLogout && dispatch(userLoginRefresh())
        dispatch({
          type: USER_DESTROY_IMPERSONATION_SUCCESS,
          data: response.data,
        })
        dispatch({type: RESET_LEASE})
        dispatch({type: RESET_CONDUCT})
        localStorage.removeItem('impersonation')
      })
      .catch(rethrow(() => dispatch({type: USER_DESTROY_IMPERSONATION_FAIL})))
      .catch(globalLoginErrorHandler)
      .catch(globalApiErrorHandler)
  }
