import {
  GET_LEASES_REQUEST,
  GET_LEASES_SUCCESS,
  GET_LEASES_FAIL,
  GET_LEASE_REQUEST,
  GET_LEASE_SUCCESS,
  GET_LEASE_FAIL,
  RESET_LEASE,
} from 'redux/actionTypes'

const initialState = {
  getInProgress: false,
  getDetailInProgress: false,
  data: [],
  detail: {},
  meta: {},
}

const conductsLease = (state = initialState, action) => {
  switch (action.type) {
    case GET_LEASES_REQUEST: {
      return {...state, getInProgress: true}
    }
    case GET_LEASES_SUCCESS: {
      return {
        ...state,
        getInProgress: false,
        data: action.data.objects,
        meta: action.data.meta,
      }
    }
    case GET_LEASES_FAIL: {
      return {...state, getInProgress: false}
    }

    case GET_LEASE_REQUEST: {
      return {
        ...state,
        getDetailInProgress: true,
      }
    }
    case GET_LEASE_SUCCESS: {
      return {
        ...state,
        detail: action.data,
        getDetailInProgress: false,
      }
    }
    case GET_LEASE_FAIL: {
      return {
        ...state,
        getDetailInProgress: false,
      }
    }
    case RESET_LEASE: {
      return {
        ...state,
        ...initialState,
      }
    }

    default: {
      return state
    }
  }
}

export default conductsLease
