import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import styles from './leaseDetail.module.scss'
import {DATE_FORMAT} from 'helper/constants'
import NumberFormat from 'component/NumberFormat'

const LeaseDetail = (props) => {
  const {lease} = props
  const {
    date_from,
    final_date_to,
    rent_price,
    services_price,
    services_people,
    guarantee_price,
    tenants,
  } = lease

  const getTenants = () => {
    const tenantsNames = []
    tenants.forEach((person) => tenantsNames.push(person.name))
    return tenantsNames
  }

  return (
    <div className={styles.LeaseDetail}>
      <table>
        <tbody>
          <tr>
            <td className={styles.title}>Nájemce</td>
            <td>{tenants && getTenants().join(', ')}</td>
          </tr>
          <tr>
            <td className={styles.title}>Počátek vztahu</td>
            <td>{date_from && moment(date_from).format(DATE_FORMAT)}</td>
          </tr>
          <tr>
            <td className={styles.title}>Konec vztahu</td>
            <td>{final_date_to && moment(final_date_to).format(DATE_FORMAT)}</td>
          </tr>
          <tr>
            <td className={styles.title}>Nájemné</td>
            <td>{rent_price && <NumberFormat value={rent_price} />}</td>
          </tr>
          <tr>
            <td className={styles.title}>Zálohy na služby</td>
            <td>
              {services_price !== undefined && <NumberFormat value={services_price} />}
              {' / '}
              {services_people}{' '}
              {services_people === 1 ? 'osoba' : services_people <= 4 ? 'osoby' : 'osob'}
            </td>
          </tr>
          <tr>
            <td className={styles.title}>Vratná jistota</td>
            <td>{guarantee_price && <NumberFormat value={guarantee_price} />}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

LeaseDetail.propTypes = {
  lease: PropTypes.object,
}

export default LeaseDetail
