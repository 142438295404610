import React from 'react'
import ReactNumberFormat from 'react-number-format'

const NumberFormat = ({
  value,
  thousandSeparator = ' ',
  displayType = 'text',
  suffix = ' Kč',
  decimalSeparator = ',',
}) => {
  return (
    <ReactNumberFormat
      thousandSeparator={thousandSeparator}
      displayType={displayType}
      suffix={suffix}
      decimalSeparator={decimalSeparator}
      value={parseFloat(value)}
    />
  )
}

export default NumberFormat
