import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import classNames from 'classnames/bind'
import PropTypes from 'prop-types'
import moment from 'moment'
import styles from './communicationsTable.module.scss'
import flaticon from 'asset/flaticon/flaticon.module.scss'
import {getConductCommunications} from 'redux/actions/conductCommunications'
import {DATE_FORMAT} from 'helper/constants'
import Table from './Table'
import Loader from 'component/Loader'

const cx = classNames.bind(styles)
const fi = classNames.bind(flaticon)

const CommunicationsTable = (props) => {
  const {
    conductId,
    leaseId,
    communications,
    communicationsMeta,
    getConductCommunications,
    loading,
    dataReceived,
  } = props

  const [detailOpen, setDetailOpen] = useState(null)
  const [page, setPage] = useState(0)

  useEffect(() => {
    if (conductId && leaseId) {
      getConductCommunications(conductId, leaseId, page)
    }
  }, [getConductCommunications, conductId, leaseId, page])

  const fields = [
    {key: 'date', title: 'Datum', style: styles.columnDate},
    {key: 'kind', title: 'Způsob komunikace', style: styles.columnKind},
    {key: 'text', title: 'Obsah komunikace', style: styles.communicationText},
    {key: 'detail', title: '', style: styles.columnDetail},
  ]

  const kindFormatTemplate = {
    phone: {icon: fi('flaticon-call'), text: 'Telefonát'},
    email: {icon: fi('flaticon-email'), text: 'E-mail'},
    personal: {icon: fi('flaticon-user'), text: 'Osobní schůzka'},
    sms: {icon: fi('flaticon-sms'), text: 'SMS'},
    letter: {icon: fi('flaticon-writing'), text: 'Dopis'},
    notice: {icon: fi('flaticon-info'), text: 'Vzkaz'},
  }

  const data =
    communications &&
    communications.map((item) => {
      const dateFormatted = item.date && moment(item.date).format(DATE_FORMAT)
      const kindFormat = kindFormatTemplate[item.kind]
      return {
        ...item,
        date: dateFormatted,
        kind: (
          <>
            <span className={classNames(kindFormat.icon, cx('icon'))} /> {kindFormat.text}
          </>
        ),
        text: (
          <span className={cx('communicationText')}>
            {item.text?.substring(0, 85) + (item.text?.length > 85 ? '…' : '')}
          </span>
        ),
        detail: (
          <div className={cx('detailLink')} onClick={setDetailOpen.bind(null, item.id)}>
            <span className={classNames(fi('flaticon-visibility'), cx('iconDetail'))} />
            Detail
            <div className={cx('detailOverlay', {visible: detailOpen === item.id})}>
              <div className={cx('detailBox')}>
                <div className={cx('bar')}>
                  <div className={cx('titleText')}>
                    <span className={classNames(kindFormat.icon, cx('iconTitle'))} />{' '}
                    {dateFormatted} / {kindFormat.text}
                  </div>
                  <span
                    onClick={(e) => {
                      setDetailOpen(null)
                      e.stopPropagation()
                    }}
                    className={classNames(fi('flaticon-close'), cx('close'))}
                  />
                </div>
                <div className={cx('content')}>{item.text}</div>
              </div>
            </div>
          </div>
        ),
        _key: item.id,
      }
    })

  const handleNextPage = () => {
    if (communicationsMeta.limit * (page + 1) < communicationsMeta.total_count) {
      setPage((prevState) => prevState + 1)
    }
  }

  const handlePageClick = (page) => {
    if (page > 0 || communicationsMeta.limit * (page + 1) < communicationsMeta.total_count) {
      setPage(page)
    }
  }

  const handlePreviousPage = () => {
    if (page > 0) {
      setPage((prevState) => prevState - 1)
    }
  }

  return (
    <div className={styles.CommunicationsTable}>
      {loading && !dataReceived ? (
        <Loader />
      ) : (
        <Table
          fields={fields}
          data={data}
          pagination={true}
          page={page}
          maxPage={Math.ceil(communicationsMeta.total_count / communicationsMeta.limit)}
          nextPage={handleNextPage}
          prevPage={handlePreviousPage}
          goToPage={handlePageClick}
        />
      )}
    </div>
  )
}

CommunicationsTable.propTypes = {
  conductId: PropTypes.number,
  leaseId: PropTypes.number,
  communications: PropTypes.array,
  communicationsMeta: PropTypes.object,
  getConductCommunications: PropTypes.func,
  loading: PropTypes.bool,
  dataReceived: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  communications: state.conductCommunications.data,
  communicationsMeta: state.conductCommunications.meta,
  loading: state.conductCommunications.getInProgress,
  dataReceived: state.conductCommunications.dataReceived,
})

const mapDispatchToProps = {
  getConductCommunications,
}

export default connect(mapStateToProps, mapDispatchToProps)(CommunicationsTable)
