import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import classNames from 'classnames/bind'
import {useHistory, useParams} from 'react-router-dom'
import styles from './topBar.module.scss'
import flaticon from 'asset/flaticon/flaticon.module.scss'
import logoWhite from 'asset/img/logo_white.png'
import logoIcon from 'asset/img/logo_icon.png'
import {getClientProfile, userLogout} from 'redux/actions/user'
import {MOBILE_VIEW, PHONE_SCREEN_WIDTH, TABLET_VIEW} from 'helper/constants'
import formStyles from 'component/form/formFields.module.scss'
import Select from 'react-dropdown-select'
import {getLeaseDetail} from 'redux/actions/conductLease'
import {getPickups} from 'redux/actions/documents'
import Dialog from 'component/Dialog/Dialog'
import attachment from 'asset/img/attachment.png'
import taxes from 'asset/img/taxes.png'
import TaxSummariesTable from 'component/TaxSummariesTable'
import {getConducts} from 'redux/actions/conducts'

const cx = classNames.bind(styles)
const fi = classNames.bind(flaticon)

const TopBar = (props) => {
  const {
    conducts,
    leases,
    activeLease,
    getLeaseDetail,
    impersonatedName,
    userLogout,
    loginLayout,
    loading,
    clientProfile,
    user,
    getClientProfile,
    getConducts,
  } = props

  const showImpersonation = user?.data?.can_impersonate && !user.impersonated

  const conductId = Number(useParams().conductId)
  const conduct = conducts.find((conduct) => conduct.id === conductId)
  const conductTitle = conduct?.unit?.title_with_address

  const [selectedLease, setSelectedLease] = useState()
  const [documentsToPickUp, setDocumentsToPickUp] = useState({number: 0, documents: []})
  const [documentsLoading, setDocumentsLoading] = useState(false)
  const [documentsDialogVisible, setDocumentsDialogVisible] = useState(false)

  const [taxSummariesDialogVisible, setTaxSummariesDialogVisible] = useState(false)

  const [mobileViewLogo, setMobileViewLogo] = useState(MOBILE_VIEW)

  let history = useHistory()

  const handleSelectTenant = (val) => {
    if (val[0] !== selectedLease) {
      setSelectedLease(val)
      getLeaseDetail(conductId, val[0].id)
    }
  }

  const redirectHome = () => {
    history.push('/')
  }

  const openTaxSummaries = () => {
    setTaxSummariesDialogVisible(true)
  }

  const closeTaxSummaries = () => {
    setTaxSummariesDialogVisible(false)
  }

  const openPickupDocuments = () => {
    setDocumentsDialogVisible(true)
  }

  const closePickupDocuments = () => {
    setDocumentsDialogVisible(false)
  }

  const renderToPickUpDocuments = () => {
    return (
      <>
        {documentsToPickUp.documents
          .filter((document) => document.state === 'prepared')
          .map((doc) => (
            <div key={doc.id} className={styles.notPickedDocuments}>
              <span>
                {'- '}
                {doc.name}
              </span>
              <span>
                {': '}
                {doc.description}
              </span>
            </div>
          ))}
        {documentsToPickUp.documents.filter((document) => document.state === 'prepared').length ===
          0 && (
          <div className={styles.noDocumentsToPick}>
            --- Nemáte žádné dokumenty k vyzvednutí ---
          </div>
        )}{' '}
      </>
    )
  }
  const renderPickedUpDocuments = () => {
    return (
      <>
        <div className={styles.alreadyPicked}>Již vyzvednuté dokumenty</div>
        {documentsToPickUp.documents
          .filter((document) => document.state !== 'prepared')
          .map((doc) => (
            <div key={doc.id} className={styles.alreadyPickedDocuments}>
              <span>
                {'- '}
                {doc.name}
              </span>
              <span>
                {': '}
                {doc.description}
              </span>
            </div>
          ))}
        {documentsToPickUp.documents.filter((document) => document.state !== 'prepared').length ===
          0 && (
          <div className={styles.noDocumentsToPick}>--- Nemáte žádné vyzvednuté dokumenty ---</div>
        )}
      </>
    )
  }

  useEffect(() => {
    if (!loading) {
      setSelectedLease(activeLease)
    }
  }, [loading, activeLease])

  useEffect(() => {
    if (user.logoutProgress) {
      return
    }
    if (!showImpersonation && !loginLayout) {
      getClientProfile().catch((error) => console.error(error))
      getConducts().catch((error) => console.error(error))
    }
  }, [user.logoutProgress, showImpersonation, getClientProfile, getConducts, loginLayout])

  useEffect(() => {
    if (clientProfile?.id) {
      setDocumentsLoading(true)
      getPickups().then((res) => {
        let numberOfDocuments = res.filter((doc) => doc.state === 'prepared')
        setDocumentsToPickUp({number: numberOfDocuments.length, documents: res})
        setDocumentsLoading(false)
      })
    }
  }, [clientProfile])

  const handleResizePhone = (e) => {
    if (e.target.innerWidth >= PHONE_SCREEN_WIDTH) {
      if (mobileViewLogo === true) {
        setMobileViewLogo(false)
      }
    } else {
      if (mobileViewLogo === false) {
        setMobileViewLogo(true)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', (e) => {
      handleResizePhone(e)
    })
    return () => {
      window.removeEventListener('resize', this)
    }
  }, [mobileViewLogo])

  return (
    <div className={styles.TopBar}>
      <div className={styles.mainTopBar}>
        {documentsDialogVisible && (
          <Dialog
            title="Dokumenty k vyzvednutí na pobočce"
            handleClose={closePickupDocuments}
            content={
              <div className={styles.documentsTable}>
                {renderToPickUpDocuments()}
                {renderPickedUpDocuments()}
              </div>
            }
          />
        )}

        {taxSummariesDialogVisible && (
          <Dialog
            title="Přehled příjmů a výdajů"
            handleClose={closeTaxSummaries}
            content={
              <TaxSummariesTable
                clientProfile={clientProfile}
                taxSummariesDialogVisible={taxSummariesDialogVisible}
              />
            }
          />
        )}

        <img
          onClick={redirectHome}
          className={styles.logo}
          src={mobileViewLogo ? logoIcon : logoWhite}
          alt="Váš Domovník"
        />
        {!loginLayout && (
          <>
            {clientProfile?.id ? (
              <div className={styles.documentButtons}>
                <div
                  onClick={openPickupDocuments}
                  className={styles.pickupDocuments}
                  title={'Dokumenty k vyzvednutí'}
                >
                  {!documentsLoading && !!documentsToPickUp?.number && (
                    <span className={styles.docBadge}>{documentsToPickUp?.number}</span>
                  )}
                  <img src={attachment} alt={'Dokumenty k vyzvednutí'} />
                </div>

                <div className={styles.verticalDivider} />

                <div
                  onClick={openTaxSummaries}
                  className={styles.taxDocuments}
                  title={'Přehled příjmů a výdajů'}
                >
                  <img src={taxes} alt={'Přehled příjmů a výdajů'} />
                </div>
              </div>
            ) : null}

            {!TABLET_VIEW && conductTitle && (
              <div className={styles.conductTitle}>{conductTitle}</div>
            )}

            {!MOBILE_VIEW && conductTitle && (
              <div className={styles.tenantSelect}>
                <span className={styles.tenantTitle}>Nájemce:</span>
                <div className={classNames(formStyles.selectField, styles.selectWrapper)}>
                  <Select
                    className={classNames('topBarSelect', selectedLease ? 'selected' : '')}
                    options={leases}
                    placeholder="Vyberte nájemníka"
                    values={selectedLease && [selectedLease]}
                    labelField={'tenants_with_dates'}
                    valueField={'id'}
                    searchable={false}
                    backspaceDelete={false}
                    dropdownGap={0}
                    onChange={handleSelectTenant}
                    loading={loading}
                  />
                </div>
              </div>
            )}

            <div
              className={classNames(
                styles.defaultCursor,
                styles.logoutButton,
                MOBILE_VIEW || !conductTitle ? styles.marginLeftAuto : ''
              )}
            >
              <div className={styles.logoutName}>{impersonatedName}</div>
              <div onClick={userLogout} className={styles.pointerCursor}>
                <span className={classNames(fi('flaticon-logout'), cx('iconLogout'))} />
                {!MOBILE_VIEW && <>&nbsp;&nbsp;Odhlásit se</>}
              </div>
            </div>
          </>
        )}
      </div>

      {!loginLayout && MOBILE_VIEW && conductTitle && (
        <div className={styles.mobileViewBar}>
          <span className={styles.tenantMobileTitle}>Nájemce:</span>

          <div className={classNames(styles.mobileSelectWrapper, styles.selectWrapper)}>
            <Select
              className={classNames('topBarSelect')}
              options={leases}
              values={selectedLease && [selectedLease]}
              labelField={'tenants_with_dates'}
              valueField={'id'}
              searchable={false}
              backspaceDelete={false}
              dropdownGap={0}
              onChange={handleSelectTenant}
              loading={loading}
            />
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  user: state.user,
  leases: state.conductLease.data,
  loading: state.conductLease.getInProgress,
  activeLease: state.conducts.detail.active_lease,
  conducts: state.conducts.data,
  impersonatedName: state.user.impersonatedData?.name,
  clientProfile: state.user.clientProfile,
})

const mapDispatchToProps = {
  userLogout,
  getLeaseDetail,
  getClientProfile,
  getConducts,
}

export default connect(mapStateToProps, mapDispatchToProps)(TopBar)
