import React from 'react'
import PropTypes from 'prop-types'

import styles from './paymentsState.module.scss'

const PaymentState = (props) => {
  const stateMapper = {
    paid: (
      <>
        <span className={styles.circleGreen}>●</span>&nbsp;Uhrazeno
      </>
    ),
    not_paid: (
      <>
        <span className={styles.circleRed}>●</span>&nbsp;Neuhrazeno
      </>
    ),
    partially_paid: (
      <>
        <span className={styles.circleOrange}>●</span>&nbsp;Částečně uhrazeno
      </>
    ),
  }
  return stateMapper[props.state] || null
}

PaymentState.propTypes = {
  state: PropTypes.string,
}

export default PaymentState
