import React, {useEffect, useState} from 'react'
import {getTaxSummaries} from 'redux/actions/documents'
import Loader from 'component/Loader'
import Table from 'component/Table'
import DownloadPdf from 'component/DownloadPdf'
import endpoints from 'helper/endpoints'
import classNames from 'classnames/bind'
import styles from 'component/accountingsTable.module.scss'
import flaticon from 'asset/flaticon/flaticon.module.scss'

const cx = classNames.bind(styles)
const fi = classNames.bind(flaticon)

const TaxSummariesTable = ({clientProfile, taxSummariesDialogVisible}) => {
  const [taxSummaries, setTaxSummaries] = useState([])
  const [taxSummariesLoading, setTaxSummariesLoading] = useState(false)

  const fields = [
    {key: 'year', title: 'Rok'},
    {key: 'estate_title', title: 'Název nemovitosti'},
    {key: 'pdf', title: 'PDF'},
  ]

  const data = taxSummaries?.map((item) => {
    return {
      year: item.year,
      estate_title: <div style={{whiteSpace: 'normal'}}>{item.estate_title}</div>,
      pdf: (
        <DownloadPdf
          downloadUrl={`${endpoints.taxSummaries}/${item.id}/download`}
          className={classNames(fi('flaticon-pdf-file'), cx('iconPdf'))}
        />
      ),
    }
  })

  useEffect(() => {
    if (clientProfile?.id && taxSummariesDialogVisible) {
      setTaxSummariesLoading(true)
      getTaxSummaries().then((res) => {
        setTaxSummaries(res)
        setTaxSummariesLoading(false)
      })
    }
  }, [clientProfile, taxSummariesDialogVisible])

  return (
    <div style={{color: '#3c3c3c', overflow: 'scroll'}}>
      {taxSummariesLoading ? <Loader /> : <Table fields={fields} data={data} pagination={false} />}
    </div>
  )
}

export default TaxSummariesTable
