import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import classNames from 'classnames/bind'
import {useHistory} from 'react-router-dom'
import styles from './homePage.module.scss'
import flaticon from 'asset/flaticon/flaticon.module.scss'
import {getImpersonation} from 'redux/actions/impersonation'
import HomePageMenu from 'component/HomePageMenu'
import {createUserImpersonation, destroyUserImpersonation} from 'redux/actions/user'
import formStyles from 'component/form/formFields.module.scss'
import Select from 'react-dropdown-select'
import Dialog from 'component/Dialog/Dialog'

const cx = classNames.bind(styles)
const fi = classNames.bind(flaticon)

const HomePage = (props) => {
  const {
    user,
    conducts,
    impersonation,
    getImpersonation,
    createUserImpersonation,
    destroyUserImpersonation,
    impersonationReceived,
    conductsReceived,
    getInProgress,
  } = props

  const showImpersonation = user?.data?.can_impersonate && !user.impersonated

  const [oSpolecnostiDialogVisible, setOSpolecnostiDialogVisible] = useState(false)
  const [najemniPohadkaDialogVisible, setNajemniPohadkaDialogVisible] = useState(false)
  const [selectedConduct, setSelectedConduct] = useState()

  useEffect(() => {
    if (user.logoutProgress) {
      return
    }
    if (showImpersonation) {
      getImpersonation().catch((error) => console.error(error))
    }
  }, [user.logoutProgress, showImpersonation, getImpersonation])

  const routerHistory = useHistory()

  const conductList = conducts.map((object) => {
    return {id: object.id, title: object.unit?.title_with_address}
  })

  let defaultImpersonation = null

  try {
    defaultImpersonation = JSON.parse(localStorage.getItem('impersonation'))
  } catch {}

  const [selectedImpersonation, setSelectedImpersonation] = useState(defaultImpersonation)

  const goToDetail = () => {
    if (selectedConduct?.id === 0 || selectedConduct?.id) {
      routerHistory.push(`/nemovitosti/${selectedConduct.id}`)
    }
  }

  const createImpersonation = () => {
    if (selectedImpersonation?.id === 0 || selectedImpersonation?.id) {
      createUserImpersonation(selectedImpersonation.id)
    }
  }

  const handleSelectConductChange = (value) => {
    setSelectedConduct(value[0])
  }

  const handleSelectImpersonationChange = (value) => {
    setSelectedImpersonation(value[0])
  }

  const redirectToRecommendPage = () => {
    return routerHistory.push('/doporucit')
  }

  useEffect(() => {
    if (!getInProgress) {
      if (conducts.length === 1) {
        setSelectedConduct({id: conducts[0].id, title: conducts[0].unit?.title_with_address})
      }
    }
  }, [getInProgress])

  useEffect(() => {
    window.scroll({top: 0, left: 0, behavior: 'smooth'})
  }, [])

  return (
    <div className={styles.HomePage}>
      <div className={styles.content}>
        <span className={classNames(fi('flaticon-hotel'), cx('iconHotel'))} />
        <div className={styles.greeting}>
          {user?.clientProfile?.welcome_salut || 'Vítejte v klientském portálu Váš Domovník'},
        </div>
        <div className={styles.greetingDivider} />
        <div className={styles.centerText}>
          {showImpersonation ? (
            <>níže vyberte, za kterého uživatele se chcete přihlásit </>
          ) : (
            <>
              vítejte ve své online kanceláři. Níže vyberte, do které nemovitosti chcete vstoupit{' '}
            </>
          )}
          a poté výběr potvrďte.
        </div>

        {showImpersonation ? (
          <>
            <div className={classNames(formStyles.selectField, styles.selectWrapper)}>
              <Select
                className={classNames('homePage')}
                placeholder="Vyberte uživatele"
                options={impersonation}
                searchBy={'name'}
                labelField={'name'}
                valueField={'id'}
                onChange={handleSelectImpersonationChange}
                loading={!impersonationReceived}
                noDataRenderer={() => (
                  <span className={styles.selectEmptyOption}>Žádné výsledky</span>
                )}
              />
            </div>
            <button className={styles.buttonCreateImpersonation} onClick={createImpersonation}>
              Přihlásit se
            </button>
          </>
        ) : (
          <>
            <div className={classNames(formStyles.selectField, styles.selectWrapper)}>
              {console.info()}
              {/*This is the Holy console log, this console log helps select field below to realize
              that it should rerender its default value based on state, feel free to free this
              console log, but be sure there is no easy way to help that select field!*/}
              <Select
                className={classNames('homePage', selectedConduct ? 'selected' : '')}
                values={selectedConduct && [selectedConduct]}
                placeholder="Vyberte nemovitost"
                options={conductList}
                searchBy={'title'}
                labelField={'title'}
                valueField={'id'}
                onChange={handleSelectConductChange}
                loading={!conductsReceived}
                noDataRenderer={() => (
                  <span className={styles.selectEmptyOption}>Žádné výsledky</span>
                )}
              />
            </div>
            <button className={styles.buttonEnter} onClick={goToDetail}>
              Potvrdit a vstoupit
            </button>
            {user.impersonated && (
              <button
                className={styles.buttonDestroyImpersonation}
                onClick={() => destroyUserImpersonation()}
              >
                Přihlásit se za jiného uživatele
              </button>
            )}
          </>
        )}
      </div>

      <HomePageMenu
        disablePersonalItems={showImpersonation}
        oSpolecnostiOnClick={setOSpolecnostiDialogVisible.bind(null, true)}
        najemniPohadkaOnClick={setNajemniPohadkaDialogVisible.bind(null, true)}
        doporucitZnamemuOnClick={redirectToRecommendPage}
      />

      {oSpolecnostiDialogVisible && (
        <Dialog title="O společnosti" handleClose={setOSpolecnostiDialogVisible.bind(null, false)}>
          <div className={styles.oSpolecnosti}>
            <p>Psal se rok 2013, kdy světlo světa poprvé spatřila naše společnost.</p>
            <p>
              Stovky a stovky hodin příprav se konečně přetavily v něco, v co jsme věřili, že se
              jednou stane skutečností. Našim cílem bylo už od samého počátku stvořit službu, která
              změní zažité standardy na realitním (nájemním) trhu a bude představovat skutečnou
              přidanou hodnotu. Jen tak je možné dosáhnout toho, aby měla naše práce hlubší smysl a
              mělo význam na ní dlouhodobě pracovat.
            </p>
            <p>Podařilo se!</p>
            <p>
              Dnes naše portfolio čítá stovky spokojených klientů, kterým se staráme o pronájem
              jejich nemovitostí a díky tomu přinášíme do jejich života klid, čas, pohodu a
              zpravidla i pravidelný přísun peněz.
            </p>
            <p>
              Jsme rádi, že se i Vy řadíte mezi naše klienty a pevně věříme, že tak jako ostatní
              oceníte kvalitu našich služeb a náš přístup.
            </p>
            <p>S úctou</p>
            <p>
              Martin Škoda <br />
              jednatel společnosti
            </p>
          </div>
        </Dialog>
      )}
      {najemniPohadkaDialogVisible && (
        <Dialog
          title="Nájemní pohádka"
          handleClose={setNajemniPohadkaDialogVisible.bind(null, false)}
        >
          <div className={styles.najemniPohadka}>
            <iframe
              title={'Nájemní pohádka'}
              className={styles.najemniPohadkaIFrame}
              src="https://www.youtube.com/embed/yGaNE6nwwf4"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </Dialog>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  user: state.user,
  conducts: state.conducts.data,
  conductsReceived: state.conducts.dataReceived,
  impersonation: state.impersonation.data,
  impersonationReceived: state.impersonation.dataReceived,
  getInProgress: state.conducts.getInProgress,
})

const mapDispatchToProps = {
  getImpersonation,
  createUserImpersonation,
  destroyUserImpersonation,
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)
