import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import styles from './paymentsTable.module.scss'
import {getConductPayments} from 'redux/actions/conductPayments'
import {DATE_FORMAT} from 'helper/constants'
import Table from './Table'
import PaymentState from './PaymentState'
import Loader from 'component/Loader'
import questionCircle from 'asset/img/question_circle.png'
import NumberFormat from 'component/NumberFormat'

const PaymentsTable = (props) => {
  const {
    conductId,
    leaseId,
    payments,
    getConductPayments,
    paymentsMeta,
    loading,
    dataReceived,
  } = props

  const [page, setPage] = useState(0)

  useEffect(() => {
    if (conductId && leaseId) {
      getConductPayments(conductId, leaseId, page)
    }
  }, [getConductPayments, conductId, leaseId, page])

  const fields = [
    {key: 'item', title: 'Položka', style: styles.firstColumn},
    {key: 'rent_amount', title: 'Nájemné'},
    {key: 'utilities_amount', title: 'Služby'},
    {key: 'state', title: 'Stav'},
    {
      key: 'outcome_date',
      title: (
        <div className={styles.outcomeDateTitle}>
          Datum úhrady <img src={questionCircle} alt={'help'} className={styles.helpIcon} />
          <span className={styles.helpTooltip}>
            Jedná se o datum úhrady (nájmu poníženého o poplatek za správu za daný měsíc) na Váš
            účet ze strany naší společnosti.
          </span>
        </div>
      ),
    },
  ]

  const data =
    payments &&
    payments.map((item) => {
      return {
        item: 'Nájem za ' + moment(item.period.from).format('MMMM YYYY'),
        rent_amount: <NumberFormat value={item.rent_amount} />,
        utilities_amount: <NumberFormat value={item.utilities_amount} />,
        state: <PaymentState state={item.income_payment_aggregation.status} />,
        outcome_date: item.outcome_payment_aggregation?.maturity_date
          ? moment(item.outcome_payment_aggregation.maturity_date).format(DATE_FORMAT)
          : 'Neuhrazeno',
        _key: item.period.id,
      }
    })

  const handleNextPage = () => {
    if (paymentsMeta.limit * (page + 1) < paymentsMeta.total_count) {
      setPage((prevState) => prevState + 1)
    }
  }

  const handlePageClick = (page) => {
    if (page > 0 || paymentsMeta.limit * (page + 1) < paymentsMeta.total_count) {
      setPage(page)
    }
  }

  const handlePreviousPage = () => {
    if (page > 0) {
      setPage((prevState) => prevState - 1)
    }
  }

  return (
    <div className={styles.PaymentsTable}>
      {loading && !dataReceived ? (
        <Loader />
      ) : (
        <Table
          fields={fields}
          data={data}
          pagination={true}
          page={page}
          maxPage={Math.ceil(paymentsMeta.total_count / paymentsMeta.limit)}
          nextPage={handleNextPage}
          prevPage={handlePreviousPage}
          goToPage={handlePageClick}
        />
      )}
    </div>
  )
}

PaymentsTable.propTypes = {
  conductId: PropTypes.number,
  leaseId: PropTypes.number,
  payments: PropTypes.array,
  getConductPayments: PropTypes.func,
  paymentsMeta: PropTypes.object,
  loading: PropTypes.bool,
  dataReceived: PropTypes.bool,
}

const mapStateToProps = (state) => {
  return {
    payments: state.conductPayments.data,
    paymentsMeta: state.conductPayments.meta,
    loading: state.conductPayments.getInProgress,
    dataReceived: state.conductPayments.dataReceived,
  }
}

const mapDispatchToProps = {
  getConductPayments,
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsTable)
