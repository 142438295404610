import React from 'react'
import styles from 'component/table.module.scss'
import statisticStyles from 'page/statisticsPage.module.scss'
import cx from 'classnames'
import NumberFormat from 'component/NumberFormat'

const AverageRentTable = (props) => {
  const {data} = props

  const fieldsAvgRentPrice = [
    {key: 'disposition_name', title: 'Dispozice'},
    {
      key: 'avg_rent_price',
      title: 'Průměrné nájemné',
    },
    {
      key: 'ostatni',
      title: 'Starší zástavba',
    },
    {
      key: 'novostavba',
      title: 'Novostavba',
    },
  ]

  return (
    <div className={statisticStyles.tableScroll}>
      <table className={cx(styles.table, statisticStyles.averageTable)}>
        <thead>
          <tr>
            <th rowSpan={2} className={cx('th', styles.th)}>
              Dispozice
            </th>
            <th rowSpan={2} className={cx('th', statisticStyles.thValues)}>
              Průměrné nájemné
            </th>
            <th
              rowSpan={1}
              colSpan={2}
              className={cx('th', statisticStyles.thValues, statisticStyles.spanTh)}
            >
              Stav budovy
            </th>
          </tr>
          <tr>
            <th className={cx('th', statisticStyles.thValues, statisticStyles.secondTh)}>
              Starší zástavba
            </th>
            <th className={cx('th', statisticStyles.thValues, statisticStyles.secondTh)}>
              Novostavba
            </th>
          </tr>
          <tr></tr>
        </thead>

        <tbody>
          {data
            ?.sort((a, b) => a.disposition_name[0] - b.disposition_name[0])
            .map((item, index) => (
              <tr key={index} className={styles.row}>
                {fieldsAvgRentPrice?.map((field, index) => (
                  <td key={field.key} className={cx('td', !!index && statisticStyles.tdValues)}>
                    {field.render ? (
                      field.render(item[field.key], field, item)
                    ) : item[field.key][0] !== '-' && field.key !== 'disposition_name' ? (
                      <NumberFormat value={item[field.key]} />
                    ) : (
                      item[field.key]
                    )}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
}

export default AverageRentTable
