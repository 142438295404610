import React from 'react'
import {connect} from 'react-redux'

import styles from './footer.module.scss'
import logo from 'asset/img/logo_color.png'

const Footer = () => {
  return (
    <div className={styles.Footer}>
      <img className={styles.logo} src={logo} alt="Váš Domovník" />
      <div className={styles.text}>
        © Všechna práva vyhrazena vasdomovnik.cz -{' '}
        <span className={styles.textEmphasis}>pronájem i prodej nemovitostí bez starostí</span>
      </div>
    </div>
  )
}

const mapStateToProps = () => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
